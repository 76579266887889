import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";

import "./styles.css";

export default function SuccessPage() {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!location.state || !location.state.success) {
      history.push("/");
    }
  }, [location, history]);

  return (
    <div className="success-container">
      <div className="card">
        <div className="checkmark-container">
          <i className="checkmark">✓</i>
        </div>
        <h1 className="success-header">Success!</h1>
        <p className="success-body">
          A Licensed Insurance Agent will reach out to you shortly!
        </p>
      </div>
    </div>
  );
}
