import React from "react";
import * as yup from "yup";

import { Grid, TextField, Paper, Button, Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormat from "react-number-format";

import dulyLogo from "../../assets/duly-logo.png";
import { useHistory } from "react-router";

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: "5rem",
  padding: "1rem",
}));

const StyledField = styled(TextField)(({ theme }) => ({
  margin: "1rem",
  width: "50%",
  [theme.breakpoints.only("sm")]: {
    width: "70%",
  },
  [theme.breakpoints.only("xs")]: {
    width: "90%",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: "1rem",
  width: "20%",
  fontSize: "20px",
  [theme.breakpoints.only("sm")]: {
    width: "40%",
  },
  [theme.breakpoints.only("xs")]: {
    width: "60%",
  },
}));

const schema = yup.object().shape({
  FirstName: yup
    .string()
    .matches(/^[a-zA-Z ]*$/, "Only Letters")
    .required(),
  LastName: yup
    .string()
    .matches(/^[a-zA-Z ]*$/, "Only Letters")
    .required(),
  Email: yup.string().email(),
  Phone: yup.string().required(),
});

const Home = (props) => {
  const history = useHistory();
  const [checked, setChecked] = React.useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      FirstName: "",
      LastName: "",
      Email: "",
      Phone: "",
    },
    resolver: yupResolver(schema),
  });

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    if (checked) {
      if (isValid) {
        try {
          await fetch("https://hooks.zapier.com/hooks/catch/5921686/btwygjc/", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            mode: "no-cors",
          });

          history.push({ pathname: "/success", state: { success: true } });
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={8} md={6}>
        <StyledPaper elevation={10}>
          <Grid container justifyContent="center">
            <Grid item xs={12} align="center">
              <img src={dulyLogo} alt="duly" />
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              <Grid item xs={12} align="center">
                <Controller
                  name="FirstName"
                  control={control}
                  render={({ field }) => (
                    <StyledField
                      {...field}
                      label="First Name"
                      helperText={
                        errors.FirstName ? errors.FirstName.message : null
                      }
                      error={
                        errors.FirstName
                          ? errors.FirstName.message !== null
                          : false
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <Controller
                  name="LastName"
                  control={control}
                  render={({ field }) => (
                    <StyledField
                      {...field}
                      label="Last Name"
                      helperText={
                        errors.LastName ? errors.LastName.message : null
                      }
                      error={
                        errors.LastName
                          ? errors.LastName.message !== null
                          : false
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <Controller
                  name="Email"
                  control={control}
                  render={({ field }) => (
                    <StyledField
                      {...field}
                      label="Email"
                      helperText={errors.Email ? errors.Email.message : null}
                      error={
                        errors.Email ? errors.Email.message !== null : false
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} align="center">
                <Controller
                  name="Phone"
                  control={control}
                  render={({ field }) => (
                    <NumberFormat
                      customInput={StyledField}
                      format="(###) ###-####"
                      allowEmptyFormatting
                      mask="_"
                      label="Phone Number"
                      helperText={errors.Phone ? errors.Phone.message : null}
                      error={
                        errors.Phone ? errors.Phone.message !== null : false
                      }
                      inputProps={{
                        inputMode: "numeric",
                        style: { textAlign: "center" },
                      }}
                      onValueChange={(values) => {
                        const { value } = values;
                        field.onChange(value);
                      }}
                      onBlur={(e) => field.onBlur(e)}
                      value={field.value}
                      ref={field.ref}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="By checking this box, I agree to be called by a licensed insurance agent to learn more about my Medicare options."
                  control={
                    <Checkbox checked={checked} onChange={handleCheck} />
                  }
                />
              </Grid>
              <Grid item xs={12} align="center">
                <StyledButton variant="contained" type="submit">
                  Submit
                </StyledButton>
              </Grid>
            </form>
          </Grid>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};

export default Home;
